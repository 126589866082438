import React, { useImperativeHandle, useRef, forwardRef, useState } from "react";
import axios from "axios";
import styles from "./UploadImage.module.scss";

const UploadImage = forwardRef((props, ref) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);

    useImperativeHandle(ref, () => ({
        handleUpload: async () => {
            if (!file) return;

            const formData = new FormData();
            formData.append("image", file);

            try {
                const response = await axios.post(
                    process.env.REACT_APP_URL + "/uploads",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                const { imageUrl } = response.data;
                return imageUrl;
            } catch (error) {
                console.error("Error uploading image:", error);
                alert("Failed to upload image");
            }
        },
    }));

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    return (
        <div className={styles.file_upload_container}>
            <input
                ref={fileInputRef}
                type="file"
                id="fileInputImage"
                className={styles.file_input}
                accept="image/*"
                onChange={handleFileChange}
            />
        </div>
    );
});

export default UploadImage;
