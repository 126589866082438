import React from 'react'
import styles from "./OrderList.module.scss"
import { Button } from '../../ui/Button/Button'
import { deliveredOrder } from '../../services/order'
import { Loading } from '../Loading/Loading'

export const OrderList = ({ list, change }) => {

    return (
        <div className={styles.order_ul}>{list.length ? list.map((order, i) => {
            return (<div className={styles.order_li} key={i}>
                <p>{order.name} {order.phone}</p>
                <span>{order.address}</span>
                <span>{order.details}</span>
                <div className={styles.order_basket}>
                    {order.basket.map((food, i) => {
                        return <span key={i}>{food.name} ({food.count} հատ) {food.count * food.price}֏</span>
                    })}
                </div>
                <span style={{ textAlign: "right", borderBottom: "1px solid", paddingBottom: "5px" }}>{order.totalAmount}֏</span>
                <span>վճարում - {order.paymentStatus}</span>
                <span>վիճակ - {order.orderStatus}</span>
                <div className={styles.order_actions}>
                    <Button onClick={() => {
                        change()
                        deliveredOrder(order._id)
                    }} size='small'>Առաքված</Button>
                </div>
            </div>)
        }) : <Loading />}</div>
    )
}
