import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from "./Header.module.scss"
import { Button } from '../../ui/Button/Button'
import { Basket } from '../../ui/Basket/Basket'

export const Header = ({ isChange }) => {
    const navigate = useNavigate();
    const gotoFragment = (fragment) => {
        setTimeout(() => {
            const element = document.getElementById(fragment);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            } else {
                navigate("/")
            }
        }, 0);
    };


    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };



    return (
        <div className={`${styles.header} container`} id='welcome'>
            <div onClick={() => navigate("/")} className={styles.logo}>
                <img src="/logo192.png" alt="" />
                <h4>Կառնուտ շաուրմա</h4>
            </div>
            <div className={styles.navs}>
                <Button onClick={() => { navigate("/main"); window.scrollTo({ top: 0, behavior: 'smooth' }) }} variant='link'>Բարի գալուստ</Button>
                <Button onClick={() => gotoFragment('menu')} variant='link'>Մենյու</Button>
                <Button onClick={() => gotoFragment('services')} variant='link'>Ծառայություններ</Button>
                <Button onClick={() => gotoFragment('contact')} variant='link'>Կապ</Button>
            </div>
            <div className={styles.navs_mobile}>
                <button
                    className={`${styles.burger} ${isActive ? styles.active : ''}`}
                    onClick={toggleMenu}
                    aria-label="Toggle menu"
                >
                    <span className={styles.burgerLines}></span>
                </button>
                <div onClick={toggleMenu} className={isActive ? styles.navs_bg : ""}></div>
                <div className={`${styles.burgerNavs} ${isActive ? styles.active : ""}`} >
                    <Button onClick={() => { navigate("/main"); window.scrollTo({ top: 0, behavior: 'smooth' }); toggleMenu() }} variant='link'>Բարի գալուստ</Button>
                    <Button onClick={() => { gotoFragment('menu'); toggleMenu() }} variant='link'>Մենյու</Button>
                    <Button onClick={() => { gotoFragment('services'); toggleMenu() }} variant='link'>Ծառայություններ</Button>
                    <Button onClick={() => { gotoFragment('contact'); toggleMenu() }} variant='link'>Կապ</Button>
                </div>
            </div>
            <div className={styles.basket}><Basket change={isChange} /></div>
        </div>
    )
}
