import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from "./CheckoutForm.module.scss"
import { Button } from '../../ui/Button/Button';
import { createOrder } from '../../services/order';
import { useNavigate } from 'react-router-dom';

export const CheckoutForm = () => {
    const [basket, setBasket] = useState([]);

    useEffect(() => {
        const localBasket = JSON.parse(localStorage.getItem("basket"))

        if (localBasket) {
            setBasket(localBasket)
        }
    }, [])
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate()

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const orderData = {
            name: data.name,
            phone: data.phone,
            address: data.address,
            basket: basket.map(food => ({ foodId: food._id, count: food.count, price: food.price, name: food.name })),
            details: data.orderDetails,
            totalAmount: basket.reduce((ttl, { price, count }) => ttl + price * count, 0)
        }
        createOrder(orderData).then(data => {
            alert('Order Submitted Successfully!');
            reset();
            setIsSubmitting(false);
            localStorage.removeItem("basket")
            navigate("/")
        }).catch(err => console.log(err))
    };

    return (
        <div className={`${styles.checkout}`}>
            <form className={styles.checkout_form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.checkout_form_group}>
                    <label htmlFor="name">Անուն Ազգանուն</label>
                    <input
                        type="text"
                        id="name"
                        {...register('name', { required: 'Անուն Ազգանուն պարտադիր է լրացնել' })}
                    />
                    {errors.name && <p className="error">{errors.name.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="phone">Հեռախոսահամար</label>
                    <input
                        type="text"
                        id="phone"
                        {...register('phone', { required: 'Հեռախոսահամարը պարտադիր է լրացնել' })}
                    />
                    {errors.phone && <p className="error">{errors.phone.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="address">Հասցե</label>
                    <input
                        type="text"
                        id="address"
                        {...register('address', {
                            required: 'Հասցեն պարտադիր է լրացնել',
                        })}
                    />
                    {errors.address && <p className="error">{errors.address.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="orderDetails">Պատվերի մանրամասներ</label>
                    <textarea
                        id="orderDetails"
                        {...register('orderDetails')}
                    ></textarea>
                    {errors.orderDetails && <p className="error">{errors.orderDetails.message}</p>}
                </div>

                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Հաստատում...' : 'Հաստատել պատվերը'}
                </Button>
            </form>
        </div>
    );
};
