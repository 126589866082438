import React, { useEffect, useState } from 'react'
import styles from "./Main.module.scss"
import { Header } from '../../components/Header/Header'
import { Welcome } from '../../components/Welcome/Welcome'
import { Menu } from '../../components/Menu/Menu'
import { Contact } from '../../components/Contact/Contact'
import { Services } from '../../components/Services/Services'
import { Footer } from '../../components/Footer/Footer'

export const Main = () => {
    const [change, setChange] = useState(0)
    const handleChange = () => {
        setChange(change + 1)
    }
    return (
        <div>
            <Header isChange={change} />
            <Welcome />
            <Menu change={handleChange} />
            <Services />
            <Contact />
            <Footer />
        </div>
    )
}
