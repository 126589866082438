import React, { useEffect, useState } from 'react'
import { Footer } from '../../components/Footer/Footer'
import styles from "./Admin.module.scss"
import { checkAuth } from '../../services/user'
import { useNavigate } from 'react-router-dom'
import { getOrders } from '../../services/order'
import { getFood } from './../../services/food';
import { FoodList } from '../../components/FoodList/FoodList';
import { OrderList } from '../../components/OrderList/OrderList'

export const Admin = () => {
    const navigate = useNavigate()
    const [orders, setOrders] = useState([])
    const [foods, setFoods] = useState([])
    const [change, setChange] = useState(0)
    useEffect(() => {
        setFoods([])
        setOrders([])
        if (!checkAuth()) {
            navigate("/login")
        } else {
            getOrders().then((data) => setOrders(data)).catch(err => console.log(err))
            getFood().then((data) => setFoods(data)).catch(err => console.log(err))
        }
    }, [change, navigate])
    return (
        <div>
            <div className={`container ${styles.lists}`}>
                <OrderList list={orders} change={() => setChange(change + 1)} />
                <FoodList list={foods} change={() => setChange(change + 1)} />
            </div>
            <Footer />
        </div>
    )
}
