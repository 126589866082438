import React, { useEffect, useState } from 'react'
import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'
import { CheckoutForm } from './../../components/CheckoutForm/CheckoutForm';
import styles from "./Checkout.module.scss"
import { BasketList } from '../../components/BasketList/BasketList';

export const Checkout = () => {
    const [change, setChange] = useState(0)
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    })
    const handleChange = () => {
        setChange(change + 1)
    }
    return <div className={styles.checkoutPage}>
        <Header isChange={change} />
        <div className={`container ${styles.checkoutInfo}`}>
            <BasketList image={true} />
            <CheckoutForm infoChange={handleChange} />
        </div>
        <Footer />
    </div>

}
