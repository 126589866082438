import React, { useState, useEffect } from 'react';
import styles from './BasketList.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export const BasketList = ({ image = false, change, infoChange = () => { } }) => {
    const [basket, setBasket] = useState([]);
    const navigate = useNavigate()
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        const localBasket = JSON.parse(localStorage.getItem("basket"))

        if (localBasket) {
            setBasket(localBasket)
        }

        infoChange()
    }, [change])


    // Increment item count
    const incrementCount = (index) => {
        const updatedBasket = [...basket];
        updatedBasket[index].count += 1;
        setBasket(updatedBasket);
        localStorage.setItem("basket", JSON.stringify(updatedBasket))
        if (updatedBasket.length == 0 && pathname == "/checkout") {
            navigate("/")
        }
        infoChange()

    };

    const decrementCount = (index) => {
        const updatedBasket = [...basket];
        if (updatedBasket[index].count > 1) {
            updatedBasket[index].count -= 1;
            localStorage.setItem("basket", JSON.stringify(updatedBasket))
            setBasket(updatedBasket);
        } else {
            updatedBasket.splice(index, 1);
            localStorage.setItem("basket", JSON.stringify(updatedBasket))
            setBasket(updatedBasket);
        }
        if (updatedBasket.length == 0 && pathname == "/checkout") {
            navigate("/")
        }
        infoChange()

    };

    const total = (products) => {
        return products.reduce((ttl, { price, count }) => ttl + price * count, 0);
    };

    return (
        <div className={styles.food_ul}>
            {basket.map((food, i) => {
                return (
                    <div className={styles.food_li} key={i}>
                        {image && <img width={60} src={`${food.image}`} alt={food.name} />}
                        <span>
                            {food.name} ({food.count}x) - {food.price * food.count}֏
                        </span>
                        <div className={styles.actions}>
                            <button
                                className={styles.actions_btn}
                                onClick={() => incrementCount(i)}
                            >
                                +
                            </button>
                            <button
                                className={styles.actions_btn}
                                onClick={() => decrementCount(i)}
                            >
                                -
                            </button>
                        </div>
                    </div>
                );
            })}
            {image && <p>Ընդամենը {total(basket)}֏</p>}
        </div>
    );
};
