import React from 'react'
import styles from "./Login.module.scss"
import { useForm } from 'react-hook-form';
import { Button } from '../../ui/Button/Button';
import { login } from '../../services/user';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate()


    const onSubmit = async (data) => {
        login(data).then(dt => {
            navigate("/admin")
        })
    }

    return (
        <div className={styles.loginPage}>
            <form className={styles.loginPage_form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.loginPage_form_group}>
                    <input
                        placeholder='login'
                        type="text"
                        id="name"
                        {...register('name', { required: '' })}
                    />
                    <input
                        placeholder='password'
                        type="password"
                        id="password"
                        {...register('password', { required: '' })}
                    />
                    <Button type='submit'>Login</Button>
                </div>
            </form>
        </div>
    )
}
