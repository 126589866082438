import axios from "axios";

export const login = async (form) => {
  return axios
    .post(`${process.env.REACT_APP_URL}/auth/login`, form)
    .then(({ data }) => {

      localStorage.setItem("token", data.token);
    })
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const checkAuth = () => {
  const token = window.localStorage.getItem("token");

  if (token !== null) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_URL}/auth/me`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.data) {
            resolve(true);
          } else {
            window.localStorage.removeItem("token");
            resolve(false);
          }
        })
        .catch((err) => {
          window.localStorage.removeItem("token");
          resolve(false);
          console.log(err);
        });
    });
  } else {
    return false;
  }
};
