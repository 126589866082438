import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import './App.scss';
import { Main } from "./pages/Main/Main";
import { Checkout } from "./pages/Checkout/Checkout";
import { Admin } from "./pages/Admin/Admin";
import { Login } from "./pages/Login/Login";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    localStorage.setItem("basket", "[]")
  })
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={`/main`} />} />
          <Route path="/main" element={<Main />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
