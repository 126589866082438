import React, { useEffect, useState } from 'react'
import { FoodCard } from '../../ui/FoodCard/FoodCard'
import styles from "./Menu.module.scss"
import { getFood } from '../../services/food'

export const Menu = ({ change }) => {
  const [menu, setMenu] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFood();
        setMenu(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch food data");
        setLoading(false);
      }
    }
    fetchData()
  }, [])
  const handleChange = ( ) => {
    change()
  }


  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>{error}</div>; // Show error message if any
  }
  return (<div className={`${styles.menu} container`} id='menu'>
    <h3>Մենյու</h3>
    <div className={`${styles.menu_list}`}>
      {menu.map((food, i) => {
        return <FoodCard food={food} key={i} change={handleChange} />
      })}
    </div></div>
  )
}
