import axios from "axios";

export const createOrder = async (basket) => {
  return axios
    .post(`${process.env.REACT_APP_URL}/order/create`, basket)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const getOrders = async () => {
  return axios
    .get(`${process.env.REACT_APP_URL}/order/getall`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const deliveredOrder = async (id) => {
  const token = window.localStorage.getItem("token");
  return axios
    .post(
      `${process.env.REACT_APP_URL}/order/update/${id}`,
      { orderStatus: "Առաքված" },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};
