import React from 'react'
import styles from "./Footer.module.scss"
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa';

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className='container'>
                <span>© Հեղինակային իրավունք Կառնուտ Շաուրմա. Բոլոր իրավունքները պաշտպանված են: 2024</span>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <a href="tel:+37455109050">+(374)55-109-050</a>
                    <FaCcVisa size={20} />
                    <FaCcMastercard size={20} />
                </div>
            </div>
        </footer>
    )
}
